import { useCallback, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Dialog } from '@heidi-pay/heidi-component-library/components/Dialog';
import { FillHeightContainer } from '@heidi-pay/heidi-component-library/components/Layout';
import { Box } from '@mui/material';
import {
  IBaseInputComponent,
  IComponentBaseOptions,
  IValidationError,
} from '@transaction/components/types';
import { isTouchScreenDevice } from '@utils';
import { ComplianceContentCarousel } from './components/ComplianceContentCarousel';
import { ComplianceElementComponent } from './components/ComplianceElementComponent';
import { getComplianceDetails } from './content/getComplianceContainerContent';
import { ComponentTypes } from './enums';
import { IComplianceElement } from './types';
import { complianceContainerValidation } from './validation';

interface IConsentFieldValue {
  key: string;
  value: boolean | undefined;
}

interface IComplianceContainerWithConsentsOptions
  extends IComponentBaseOptions {
  isSwipeInteractionEnabled?: boolean;
  mandatoryAcceptanceKeys?: string[];
}

export const ComplianceContainerWithConsents = ({
  error: validationErrors = [],
  name,
  onChange,
  options = {},
  value = [],
}: IBaseInputComponent<
  IComplianceContainerWithConsentsOptions,
  IConsentFieldValue[],
  IValidationError[]
>) => {
  const { isSwipeInteractionEnabled } = options;

  const { t } = useTranslation('compliance');

  const [dialogId, setDialogId] = useState<string>();

  const { dialogContent = [], formContent } = getComplianceDetails(name);
  const { title } = dialogContent.find(x => x.id === dialogId) ?? {};
  const currentDialogIndex = dialogContent.findIndex(
    item => item.id === dialogId,
  );
  const stringValues = value.map(item => item.value?.toString());

  const handleOnClose = useCallback(() => setDialogId(undefined), []);

  const handleDialogOpen = useCallback(
    (dialogIdParam?: string) => () => {
      setDialogId(dialogIdParam);
    },
    [],
  );

  const handleOnCarouselNavigation = useCallback(
    (index: number) => {
      setDialogId(dialogContent[index].id);
    },
    [dialogContent],
  );

  const handleSignatureChange = useCallback(
    (consentId: string, _value: boolean) => {
      const newArray = value.map(item =>
        item.key === consentId ? { ...item, value: _value } : item,
      );
      onChange(newArray);
    },
    [onChange, value],
  );

  const formConsentElemenetIds = formContent
    .filter(item =>
      [
        ComponentTypes.ConsentBlock,
        ComponentTypes.ConsentBlockWithoutLabel,
      ].includes(item.componentType),
    )
    .map(item => item.id);

  const getValueAndValidationErrorKey = useCallback(
    (element: IComplianceElement) => {
      if (!formConsentElemenetIds.includes(element.id)) {
        return {};
      }

      const elementIndex = formConsentElemenetIds.indexOf(element.id);
      const validationErrorObj = validationErrors[elementIndex];
      const elementValue = stringValues[elementIndex];

      return {
        elementValidationErrorKey:
          validationErrorObj?.message ?? validationErrorObj?.value?.message,
        elementValue,
      };
    },
    [formConsentElemenetIds, validationErrors, stringValues],
  );

  const Title = title ? t(title.translationKey) : null;

  return (
    <>
      <Box lineHeight="20px" marginY="20px">
        {formContent.map(element => (
          <ComplianceElementComponent
            element={element}
            key={element.id}
            onConsentBlockClick={handleSignatureChange}
            onDialogLinkClick={handleDialogOpen(element.dialogId)}
            {...getValueAndValidationErrorKey(element)}
          />
        ))}
      </Box>
      <Dialog isOpen={!!dialogId} onCloseClick={handleOnClose} title={Title}>
        <FillHeightContainer sx={{ overflow: 'hidden' }}>
          <ComplianceContentCarousel
            carouselItems={dialogContent}
            currentIndex={currentDialogIndex}
            enableSwipeInteraction={
              isTouchScreenDevice && isSwipeInteractionEnabled
            }
            onNavigationChange={handleOnCarouselNavigation}
            onSignatureChange={handleSignatureChange}
            validationErrors={validationErrors}
            values={stringValues}
          />
        </FillHeightContainer>
      </Dialog>
    </>
  );
};

ComplianceContainerWithConsents.validation = complianceContainerValidation;
