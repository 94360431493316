import {
  Carousel,
  ContentHeightContainer,
  FillHeightContainer,
} from '@heidi-pay/heidi-component-library/components';
import { IValidationError } from '@transaction/components/types';
import { IComplianceElement } from '../types';
import { ComplianceElementComponent } from './ComplianceElementComponent';

interface ICarouselItem {
  complianceElements: IComplianceElement[];
  signatureElement?: IComplianceElement;
}

export interface ICarouselItemProps extends ICarouselItem {
  onSignatureChange: (consentId: string, value: boolean) => void;
  validationErrorKey?: string;
  value?: string;
}

export const CarouselItem = ({
  complianceElements,
  onSignatureChange,
  signatureElement,
  validationErrorKey,
  value,
}: ICarouselItemProps) => (
  <>
    <FillHeightContainer>
      {complianceElements.map(element => (
        <ComplianceElementComponent
          element={element}
          elementValue={value}
          key={element.id ?? element.translationKey}
          onConsentBlockClick={onSignatureChange}
        />
      ))}
    </FillHeightContainer>
    {signatureElement ? (
      <ContentHeightContainer pt="20px">
        <ComplianceElementComponent
          element={signatureElement}
          elementValidationErrorKey={validationErrorKey}
          elementValue={value}
          key={signatureElement.translationKey}
          onConsentBlockClick={onSignatureChange}
        />
      </ContentHeightContainer>
    ) : null}
  </>
);

export interface IComplianceContentCarouselProps {
  carouselItems: ICarouselItem[];
  currentIndex: number;
  enableSwipeInteraction?: boolean;
  onNavigationChange: (index: number) => void;
  onSignatureChange: (consentId: string, value: boolean) => void;
  validationErrors?: IValidationError[];
  values?: (string | undefined)[];
}

export const ComplianceContentCarousel = ({
  carouselItems,
  currentIndex,
  enableSwipeInteraction = false,
  onNavigationChange,
  onSignatureChange,
  validationErrors,
  values,
}: IComplianceContentCarouselProps) => {
  const carouselItemComponents = carouselItems.map((item, index) => (
    <CarouselItem
      complianceElements={item.complianceElements}
      key={`${item.complianceElements[0].translationKey}`}
      onSignatureChange={onSignatureChange}
      signatureElement={item.signatureElement}
      validationErrorKey={validationErrors?.[index]?.message}
      value={values?.[index]}
    />
  ));

  return (
    <Carousel
      controlledIndex={currentIndex}
      enableSwipeInteraction={enableSwipeInteraction}
      items={carouselItemComponents}
      onChange={onNavigationChange}
    />
  );
};
